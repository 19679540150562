/*
 * Author: http://stuffandnonsense.co.uk/blog/about/hardboiled_css3_media_queries/
 */
@import "Variables";


/* Smartphones and tablets */
@media only screen and (max-width: 1024px) {
  /* Styles */
  h1 {
    font-size: 36px;
    margin-bottom: 1em;
  }

  #realizace .content .section-heading {
    margin: 0;
  }

  .cards-wrapper.d-flex {
    display: block;
  }


  .card {
    margin: 1em 0;
    //padding: 1.5em 1.5em 4em;
    padding: 1.5em;
    min-height: auto;
  }

  .card .card-header {
    word-break: break-word;
    width: 80%;
    font-size: 28px;
    margin-bottom: .5em;
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-width : 320px)
and (max-width : 480px) {


  //body {
  //  overflow: auto;
  //}
  body {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
  }



  p,
  ul li {
    font-size: 17px;
  }

  li svg {
    font-size: 28px;
  }

  .btn {
    font-size: 17px;
  }

  .hide-mobile {
    display: none;
  }

  .menu-icon {
    display: block;
    float: right;
    font-size: 2em;
  }



  .section#co-delame {
    padding-bottom: 0;
  }

  .section#realizace .section-header {
    margin-bottom: 2em;
  }




  .main-nav .main-nav-list {
    position: absolute;
    left: 0;
    right: 0;
    top: -1em;
    transition: all .5s;
    display: block;
  }

  .main-nav.shown .main-nav-list {
    top: 65px;
    background-color: #161616;
    z-index: 1000;
    //max-height: 300px;
  }

  .main-nav-list li {
    text-align: center;
    margin-bottom: 1.5em;
    display: none;
    position: relative;
  }

  .main-nav-list li:last-of-type {
    margin-bottom: 1em;
  }

  .main-nav-list li::after {
    position: absolute;
    bottom: -.75em;
    left: 0;
    right: 0;
    background-color: $grey;
    height: 1px;
    content: "";
  }

  .main-nav-list li:last-of-type::after {
    display: none;
  }

  .main-nav.shown .main-nav-list li {
    display: block;
  }

  .d-flex {
    display: block;
  }

  li.d-flex {
    display: flex;
  }

  li.d-flex span,
  li.d-flex a {
    align-self: center;
  }

  .main-heading h1 {
    font-size: 42px;
    margin-bottom: 0;
    padding-bottom: 2rem;
  }


  .element-dropsquare-wrapper {
    position: absolute;
    top: 6em;
    height: auto;
  }

  .section.hero .main-heading .text {
    bottom: 7em;
    position: absolute;
    z-index: 1000;
  }

  .first .main-heading .text::after {
    bottom: unset !important;
  }

  .first .main-heading img {
    height: 55vh;
  }

  .section#o-nas .about-us h1 {
    margin-bottom: 1em;
    margin-top: 0;
    margin-right: auto;
  }

  .section {
    height: 100% !important;
    padding-top: 4.5rem;
    padding-bottom: 2rem;
  }

  .section#co-delame {
    padding-top: 4.5rem;
  }

  .section.hero {
    height: 100vh !important;
    overflow: visible;
  }

  .section.hero .main-heading .text::after {
    bottom: -15vh;
  }

  .section#o-nas .about-us p {
    line-height: 1.2em;
  }

  .section.realizace .content .section-description::after {
    top: 0;
    bottom: 0;
    left: 2px;
  }

  .card .card-number {
    bottom: 1em;
  }

  .section.realizace .section-description {
    padding-left: 1em;
    margin-top: 1em;
  }


  .gallery img {
    width: 100%;
  }

  .gallery .image-wrapper,
  .gallery .image-wrapper img {
    width: 100%;
  }

  .section.kontakt-form .section-heading:after {
    display: none;
  }

  .section.kontakt-form .form-wrapper form {
    width: 100%;
    min-width: auto;
  }

  .section.kontakt-form .form-wrapper .form-item input {
    background-color: $grey;
  }

  .section.kontakt-form .form-wrapper .form-item input,
  .section.kontakt-form .form-wrapper .form-item label {
    padding-left: 1em;
  }

  .section.kontakt-form .form-wrapper .form-item.textarea label {
    padding-left: 0;
  }

  .section.kontakt-form .form-wrapper form .form-item:first-of-type {
    margin-top: 0;
  }

  .section.kontakt-form .form-wrapper button {
    width: 100%;
  }

  .section.kontakty .contacts li .icon {
    margin-right: 10px;
  }

  .slick-slide {
    max-height: 50vh;
  }

  .slick-slide > div {
    padding: 0;
    height: 100%;
  }

  .slick-item {
    height: 50vh;
  }

  .slick-item img {
    height: 100%;
    object-fit: contain;
  }

  .slick-list {
    margin: 0 !important;
  }

  .section.realizace .gallery .arrow-left {
    left: -1em;
  }

  .section.realizace .gallery .arrow-right {
    right: -1em;
  }

  #o-nas .about-us h1::after {
    display: none;
  }

  .info {
    display: block;
    margin: 2em 0;
  }



  .mapbox-container {
    left: -2em;
    right: -2em;
  }

  .main-footer {
    border-top: 0;
  }

  .pager-wrapper {
    display: none;
  }

  .mapbox-wrapper .element-dropsquare-wrapper {
    position: initial;
  }

  .mapbox-wrapper .dropsquare {
    display: none;
  }


}


/* Smartphones and tablets ----------- */
@media only screen
and (min-width : 321px) and (max-width: 1024px){
  /* Styles */
  .section#kontakty {
    padding-bottom: 4em;
  }

  .mapbox-wrapper {
    height: 40vh;
  }





}

/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
  /* Styles */
}

@media (min-width : 480px) {
  .hide-desktop {
    display: none;
  }
}


/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  /* Styles */

  .section {
    height: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow: unset;
  }

  .section.hero .main-heading .text::after {
    display: none;
  }
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
  /* Styles */
  .hide-desktop {
    display: none;
  }

  .main-heading .text {
    width: 55%
  }

  .section.kontakt-form .form-wrapper form {
    width: 430px;
  }




  #progressBarWrapper {
    height: 35vh;
    position: fixed;
    right: 2em;
    width: 2px;
    background-color: $grey;
    top: 50%;
    transform: translateY(-50%);
  }

  #progressBar {
    width: 100%;
    background-color: $offWhite;
  }


}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  /* Styles */
  .section#o-nas {
    height: 65vh;
  }
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
  /* Styles */
}
