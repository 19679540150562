@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@import "Variables";

body {
  font-family: "Source Sans Pro", sans-serif;
  background-color: #161616;
  color: #fff;
  box-sizing: border-box;
  overflow-x: hidden;
}

html{
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  //box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


h1 {
  font-size: $h1-size;
}

p, ul li {
  font-size: 25px;
  line-height: 120%;
}

ul {
  list-style: none;
}

.d-flex {
  display: flex;
}

.p-1 {
  padding: 1em;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.main-header {
  border-bottom: 1px solid #C4C4C4;
    position: fixed;
    top: 0;
    left: 0;
    //right: 0;
    width: 100%;
    z-index: 10000;
    background-color: #171717;
  box-sizing: border-box;
}

.company-logo {
  height: 27px;
}

.main-nav-list {
  list-style: none;
  display: flex;
}

.main-nav-list li {
  font-size: 22px;
  margin-bottom: 0;
}

.main-nav-list li a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 1em;
  transition: color .5s;
}

.main-nav-list li a:hover {
  color: $offWhite;
}

.bold {
  font-weight: bold;
}

.nav-name {
  font-size: 28px;
}

.btn {
  background-color: $grey;
  border: none;
  color: #ffffff;
  padding: 1em 2em;
  font-size: 23px;
}

.btn-icon {
  margin-left: .5em;
}

//img.with-dropsquare {
//  position: relative;
//}
//
//img.with-dropsquare::before {
//  position: absolute;
//  display: block;
//  width: 100%;
//  height: 100%;
//  content: "";
//  background-color: $grey;
//  transform: translate(50%);
//
//}



.element-dropsquare-wrapper {
  position: relative;
  //width: 100%;
  height: 100%;
}

.dropsquare {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  background-color: $grey;
  transform: translate(25px, 20px);
}

.element-dropsquare-wrapper img {
  z-index: 200;
  position: relative;
}

.container {
  position: relative;
  width: 100%;
  height: calc(100vh);
  overflow: auto;
  padding: 0 1em;
  box-sizing: border-box;
}

.section {
  position: relative;
  //width: 100%;
  height: 100vh;
  padding: 1em;
  overflow: hidden;
}

.toAnimate {
  opacity: 0;
}

.toAnimate.animation__animated {
  opacity: 1;
}

.section .content {
  display: flex;
  max-width: 1240px;
  margin: 0 auto;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  height: 100%;
}



.section.hero .main-heading .text{
  position: relative;
}

.section.hero .main-heading .text::after {
  height: 100px;
  content: "";
  position: absolute;
  left: 6px;
  bottom: -22vh;
  width: 1px;
  background-color: $offWhite;
}

.section#o-nas {
  height: 70vh;
}


.section#o-nas .about-us h1{
  flex: 1 1 auto;
  white-space: nowrap;
  margin-right: .5em;
  margin-top: -1.5em;
  position: relative;
}

.section#o-nas .about-us h1::after{
  position: absolute;
  top: 7.5rem;
  width: 100px;
  height: 1px;
  right: 0;
  content: "";
  background-color: $offWhite;
}



.section#o-nas .about-us p {
  width: 80%;
}

.section#co-delame {
  height: 100%;
  padding-bottom: 7em;
  padding-top: 4rem;
}

.section.our-work .section-heading{
  margin-bottom: 1em;
  width: 40%;
}


.card {
  display: block;
  flex: 1 1 0px;
  background-color: $grey;
  padding: 2.8em 2em 0;
  margin: 0 2em;
  min-height: 430px;
  position: relative;
}

.card:first-of-type {
  margin-left: 0;
}

.card:last-of-type {
  margin-right: 0;
}

.card .card-header {
  font-size: 40px;
  margin-bottom: 1em;
}

.card-number {
  position: absolute;
  bottom: 1.5em;
  left: 6em;
}

.card-number::before {
  position: absolute;
  content: "";
  left: -7em;
  top: 50%;
  width: 100px;
  height: 1px;
  background-color: $offWhite;
}

.section.realizace .section-header{
  margin-bottom: 5em;
  width: 100%;
}

.section.realizace .content .d-flex:first-of-type{
  width: 100%;
}

.section.realizace .content .section-heading {
  flex-basis: 40%;
}

.section.realizace .content .section-description {
  flex-basis: 40%;
  position: relative;
}

.section.realizace .content .section-description::after {
  content: "";
  position: absolute;
  top: -.5em;
  bottom: -.5em;
  left: -2.2em;
  width: 1px;
  background-color: $offWhite;
}

.section.realizace .gallery {
  position: relative;
}

.section.realizace .gallery .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 45px;
  height: 50px;
  background-color: #2D2D2D;
  cursor: pointer;
}

.section.realizace .gallery .arrow img {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  transition: opacity .5s;
}

.section.realizace .gallery .arrow:hover img {
  opacity: .6;
}

.section.realizace .gallery .arrow-left {
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow svg {
  font-size: 1.5em;
  display: flex;
}

.section.realizace .gallery .arrow-right {
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section.realizace .content .gallery .image-wrapper {
  margin: 0 1em;
}

.section.realizace .content .gallery .image-wrapper:first-of-type {
  margin-left: 0;
}

.section.realizace .content .gallery .image-wrapper:last-of-type {
  margin-right: 0;
}

.section.kontakt-form .section-header {
  flex-basis: 50%;
}

.section.kontakt-form .section-heading {
  position: relative;
}

.section.kontakt-form .section-heading:after {
  position: absolute;
  bottom: -.8em;
  height: 1px;
  width: 100px;
  content: "";
  background-color: $offWhite;
  left: 0;
}

.section.kontakt-form .form-wrapper {
  flex-basis: 50%;
  display: flex;
  justify-content: flex-end;
}

.section.kontakt-form .form-wrapper form {
  //width: 80%;
  max-width: 600px;
  min-width: 400px;
  padding: 2em;
  border: 1px solid $offWhite;
}

#realizace .flex-wrapper {
  width: 100%;
}

.section.kontakt-form .form-wrapper form button {
  cursor: pointer;
  font-size: 16px;
}

.form-messages {
  margin-top: .5em;
}

.recaptcha-legal {
  margin-top: 1rem;
  display: block;
  font-size: .8rem;
}

.grecaptcha-badge {
  visibility: hidden;
}

.section.kontakty .contacts {
  margin-top: 2em;
}

li {
  font-size: 28px;
  margin-bottom: .8em;
}

a {
  color: $white;
  transition: color .5s;
}

li a.tel {
  display: block;
  margin-bottom: 5px;
}

a:hover {
  color: $offWhite;
}

.section.kontakty .contacts li .icon {
  margin-right: .8em;
  margin-top: 5px;
}

/**** CUSTOM TEXT INPUT AND TEXTAREA ****/
.form-item input.inputText:focus ~ .floating-label,
.form-item textarea.inputText:focus ~ .floating-label,
.form-item input.inputText:not(:focus):valid ~ .floating-label,
.form-item textarea.inputText:not(:focus):valid ~ .floating-label,
.form-item.forShow input.inputText ~ .floating-label,
.date-form-item.forShow input.inputText ~ .floating-label {
  transform: scale3d(0.7,0.7,1);
  top: 5px;
}


.inputText {
  border: none;
  border-bottom: 1px solid $offWhite;
  background-clip: padding-box;
  width: 100%;
  transition: 0.1s all linear;
  padding: 21px 0 10px 0;
  background-color: transparent;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
}

textarea.inputText {
  height: 25vh;
  background-color: $grey;
  padding: 25px 1.1em 10px;
  border: 0;
  resize: none;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  transform-origin: 0 0;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: top .2s, transform 0.2s;
  transition-timing-function: ease-out;
  color: $offWhite;
}

.form-item.textarea .floating-label {
  left: 1em;
}

.form-item textarea.inputText ~ .floating-label {
  top: 21px;
  transform: translateY(0);
}

.form-item {
  position: relative;
  margin: 1em 0;
  transition: color 0.4s ease;
  color: #b4b4aa;
  box-sizing: border-box;
}

.error-wrapper {
  height: 25px;
  position: relative;
  margin-left: 1em;
}

.error-wrapper .error-message {
  position: absolute;
  top: 2px;
  color: $danger;
  margin: 0;
  font-size: .8em;
}

.mapbox-wrapper {
  height: 100%;
  width: 100%;
}

.mapbox-wrapper {
  height: 100%;
  flex-basis: 65%;
  position: relative;
  outline: none;
}

.mapbox-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  outline: none;
}

.main-footer {
  text-align: center;
  padding: 2em 0;
  position: relative;
}


#kontakty .content .main-footer::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  content: "";
  background-color:  $offWhite;
}

.main-footer h2 {
  margin-bottom: 1em ;
}

.main-footer ul {
  margin: 0 auto;
  justify-content: center;
}

.main-footer li a {
  text-decoration: none;
  margin: 0 1em;
}

.main-footer .copy {
  color: $offWhite;
  font-size: 16px;
  padding-top: 1rem;
  //padding-top: 4vh;
}

.main-footer .copy a {
  color: inherit;
  text-decoration: none;
}

.menu-icon {
  display: none;
}

.pager-wrapper {
  position: absolute;
  right: 2em;
  height: 200px;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  background-color: $grey;
}

.hide-mobile {
  display: block;
}

.pager-wrapper .page-needle {
  height: 20%;
  width: 100%;
  position: absolute;
  top: 0;
  transition: all .5s;
}

.pager-wrapper .page-needle:hover {
  background-color: $offWhite;
  opacity: .5;
}

.pager-wrapper .page-needle.selected{
  background-color: $offWhite;
}

.justify-content-center {
  justify-content: center;
}

.mapbox-wrapper .element-dropsquare-wrapper {
  height: 100%;
}

#kontakty .content {
  height: 100%;
  justify-content: center;
}

.main-footer::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  height: 1px;
  background-color: $offWhite;
}
.main-footer .company-logo {
  margin-bottom: 2rem;
}

.slick-list {
  margin: 0 1em !important;
}

.slick-item {
  display: flex !important;
  justify-content: center !important;
}

.slick-slide > div {
  padding: 0 1em;
  margin: 0 auto;
}


.slick-item img {
  width: 100%;
  object-fit: contain;
}

.arrow {
  z-index: 10;
}


